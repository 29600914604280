import {
    Alert, AlertTitle,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    Grid2, IconButton,
    Input,
    Paper,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {Player} from "../../Types/Types";
import {ImportPlayers} from "../../Components/ImportPlayers/ImportPlayers";
import {DownloadButton} from "../../Components/DownloadButton/DownloadButton";
import {PDFButton} from "../../Components/PDFButton/PDFButton";
import {PDFModal} from "../../Components/PDFModal/PDFModal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Form} from "../../Components/Form/Form";
import {ShareButton} from "../../Components/ShareButton/ShareButton";
import {useIsSafari} from "../../hooks/hooks";



export const PDFCreation = () => {

    const isSafari = useIsSafari();

    /**
     * create selected players, either from local storage or empty
     */
    const [selectedPlayers, setSelectedPlayers] = useState<Player[]>(() => {
        const storedSelectedPlayers = localStorage.getItem("selectedPlayers");
        return storedSelectedPlayers ? JSON.parse(storedSelectedPlayers) : []
    });

    /**
     * create players, either from local storage or empty
     */
    const [players, setPlayers] = useState<Player[]>(() => {
        const storedPlayers = localStorage.getItem("players");
        return storedPlayers ? JSON.parse(storedPlayers) : []
    });

    /**
     * create officials, either from local storage or empty
     */
    const [official, setOfficial] = useState(() => {
        const storedOfficials = localStorage.getItem("officials");
        return storedOfficials ? JSON.parse(storedOfficials) : {
            offA: {firstName: "", lastName: ""},
            offB: {firstName: "", lastName: ""},
            offC: {firstName: "", lastName: ""},
            offD: {firstName: "", lastName: ""},
        }
    });

    /**
     * state to handle if the player creation form is shown
     */
    const [showPlayerForm, setShowPlayerForm] = useState<boolean>(false);
    /**
     * state to handle the pdf preview
     */
    const [showPdf, setShowPdf] = useState<boolean>(false);
    /**
     * sorted player list to be shown in the pdf
     */
    const [sortedPlayers, setSortedPlayers] = useState<Player[]>([]);
    /**
     * Initial player form
     */
    const [form, setForm] = useState<Player>({
        playerNumber: "",
        firstName: "",
        lastName: "",
        birthday: "",
        keeper: false,
    });
    // state for showing the snackbar
    const [snackBar, setSnackBar] = useState<{ show: boolean, message: string, type?: "info" | "success" | "warning" | "error" | undefined }>({show: false, message: ''})

    /**
     * Function to show the snackbar
     * @param show
     * @param message
     * @param type
     */
    const onShowSnackbar = (show: boolean, message: string, type?: "info" | "success" | "warning" | "error" | undefined) => {
        setSnackBar({show: show, message: message, type: type});
    }

    /**
     * Function to close the snackbar
     */
    const onCloseSnackbar = () => {
        setSnackBar({show: false, message: ''});

    }


    const [teamName, setTeamName] = useState<string>("");
    const [matchNumber, setMatchNumber] = useState<string>("");
    const [matchDate, setMatchDate] = useState<string>(new Date().toString());
    const [matchTime, setMatchTime] = useState<string>("");

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    /**
     * Deep check if player is already existing in selected players
     * @param player
     * @param selectedPlayers
     */
    const existsIn = (player: Player, selectedPlayers: Player[]) => {
        return selectedPlayers.some(selectedPlayer => JSON.stringify(selectedPlayer) === JSON.stringify(player));

    }

    /**
     * Handle input change
     * @param e
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, type, checked} = e.target;
        setForm({
            ...form,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    /**
     * Handle form cancellation
     */
    const onCancelEdit = () => {
        setIsEditing(false);
        setForm({
            playerNumber: "",
            firstName: "",
            lastName: "",
            birthday: "",
            keeper: false,
        });
    }
    /**
     * Handle form submit
     * @param e
     */
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (isEditing && editingIndex !== null) {
            const updatedPlayers = players.map((player, index) =>
                index === editingIndex ? form : player
            );
            setPlayers(updatedPlayers);
            if (selectedPlayers.some(selectedPlayer => selectedPlayer.playerNumber === form.playerNumber)) {
                setSelectedPlayers(selectedPlayers.filter(player => JSON.stringify(player) !== JSON.stringify(players[editingIndex])));
                onShowSnackbar(true, 'Spielernummer bereits im Einsatz');
            } else {
                const index = selectedPlayers.findIndex(selectedPlayer => JSON.stringify(selectedPlayer) === JSON.stringify(players[editingIndex]))
                selectedPlayers[index] = form;
                setSelectedPlayers([...selectedPlayers])
            }
            setIsEditing(false);
            setEditingIndex(null);
        } else {
            setPlayers([...players, form]);
            if (selectedPlayers.some(selectedPlayer => form.playerNumber === selectedPlayer.playerNumber)) {
                onShowSnackbar(true, 'Spielernummer bereits im Einsatz');
            } else {
                setSelectedPlayers([...selectedPlayers, form]);
            }
        }
        setForm({
            playerNumber: "",
            firstName: "",
            lastName: "",
            birthday: "",
            keeper: false,
        });
        setShowPlayerForm(false);
    };
    /**
     * Handle change in offcial fields
     * @param e
     * @param officialKey
     * @param field
     */
    const handleOfficialChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        officialKey: string,
        field: string
    ) => {
        const updatedOfficial = {
            ...official[officialKey as keyof typeof official],
            [field]: e.target.value,
        };

        setOfficial({
            ...official,
            [officialKey]: updatedOfficial,
        });
        localStorage.setItem("officials", JSON.stringify({
            ...official,
            [officialKey]: updatedOfficial,
        }));
    };

    /**
     * Handle player edit
     * @param index
     */
    const handleEdit = (index: number) => {
        setForm(players[index]);
        setIsEditing(true);
        setEditingIndex(index);
    };

    /**
     * Handle player delete
     * @param index
     */
    const handleDelete = (index: number) => {
        const updatedPlayers = players.filter((_, i) => i !== index);
        const updatedSelectedPlayers = selectedPlayers.filter((player, i) => JSON.stringify(player) !== JSON.stringify(players[index]));
        setPlayers(updatedPlayers);
        setSelectedPlayers(updatedSelectedPlayers);
       onShowSnackbar(true, 'Spieler gelöscht', 'success');
    };

    /**
     * Handle selection of player for selected player list
     * @param player
     */
    const handleSelection = (player: Player) => {
        const isSelected = existsIn(player, selectedPlayers);
        if (isSelected) {
            setSelectedPlayers(selectedPlayers.filter((p) => JSON.stringify(p) !== JSON.stringify(player)));
        } else {
            if (selectedPlayers.some(selectedPlayer => selectedPlayer.playerNumber === player.playerNumber)) {
                onShowSnackbar(true, 'Nummer bereits im Einsatz');
                return;
            }
            setSelectedPlayers([...selectedPlayers, player]);
        }
    };

    /**
     * Store players locally
     */
    useEffect(() => {
        localStorage.setItem("players", JSON.stringify(players));
    }, [players]);
    /**
     * Store officials locally
     */
    useEffect(() => {
        localStorage.setItem('officials', JSON.stringify(official))
    }, [official]);
    /**
     * Store selected players localy
     */
    useEffect(() => {
        localStorage.setItem('selectedPlayers', JSON.stringify(selectedPlayers))
    }, [selectedPlayers]);
    /**
     * Sort selected players
     */
    useEffect(() => {
        setSortedPlayers([...selectedPlayers].sort((a, b) =>
            // @ts-ignore
            a.playerNumber - b.playerNumber
        ));
    }, [selectedPlayers]);


    return  <>
        <Alert severity="info">Alle Daten werden im Browser gespeichert. Wenn Browserdaten gelöscht werden, kann
            die Spielerliste verloren gehen.</Alert>
        {isSafari && <Alert variant="filled" severity="error">
            <AlertTitle>ACHTUNG</AlertTitle>
            Du musst diese Seite in den nächsten 7 Tagen einmal öffnen, damit deine Daten nicht gelöscht werden!</Alert>}
        <ImportPlayers setPlayers={setPlayers} setSelectedPlayers={setSelectedPlayers}/>
        <div style={{padding: '1rem'}}>
            <Paper elevation={3} style={{padding: '16px', marginBottom: '16px'}}>
                <Form headline={"Spielinformationen"} formElement={[
                    {label: "Mannschaft", value: teamName, onChange: (e) => setTeamName(e.target.value)},
                    {label: "Spiel-Nr.", value: matchNumber, onChange: (e) => setMatchNumber(e.target.value)},
                    {label: "Datum", type: 'date', InputLabelProps: {shrink: true}, value: matchDate, onChange: (e) => setMatchDate(e.target.value)},
                    {label: "Uhrzeit", type: 'time', InputLabelProps: {shrink: true}, value: matchTime, onChange: (e) => setMatchTime(e.target.value)}
                ]} />
            </Paper>


            <Paper elevation={3} style={{padding: '16px', marginBottom: '16px'}}>
                <Typography variant="h5" gutterBottom>
                    Spielerliste ({sortedPlayers.length}/{players.length})
                </Typography>
                {!isEditing &&  <Button startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowPlayerForm(!showPlayerForm)}>
                    Spieler hinzufügen
                </Button> }
                {showPlayerForm && <>
                    <Typography variant="h5" gutterBottom>
                        Spieler eingeben
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2}>
                            <Grid2 size={6}>
                                <TextField
                                    fullWidth
                                    label="Trikot-Nr."
                                    name="playerNumber"
                                    value={!isEditing ? form.playerNumber : ''}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <TextField
                                    fullWidth
                                    label="Vorname"
                                    name="firstName"
                                    value={!isEditing ? form.firstName : ''}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <TextField
                                    fullWidth
                                    label="Nachname"
                                    name="lastName"
                                    value={!isEditing ? form.lastName : ''}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <TextField
                                    fullWidth
                                    label="Geburtsdatum"
                                    name="birthday"
                                    type="date"
                                    InputLabelProps={{shrink: true}}
                                    value={!isEditing ? form.birthday : ''}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="keeper"
                                            checked={!isEditing && form.keeper}
                                            onChange={handleChange}
                                        />
                                    }
                                    label="Torwart"
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <ButtonGroup>
                                    <Button type="submit" variant="contained" color="primary">
                                        {'Spieler hinzufügen'}
                                    </Button>
                                    <Button variant="contained" color="warning"
                                            onClick={() => setShowPlayerForm(false)}>
                                        Abbrechen
                                    </Button>
                                </ButtonGroup>
                            </Grid2>
                        </Grid2>
                    </form>
                </>}
                {players.map((player, index) => (
                    isEditing && editingIndex === index ?
                        <form key={index} onSubmit={handleSubmit}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={6}>
                                    <TextField
                                        fullWidth
                                        label="Trikot-Nr."
                                        name="playerNumber"
                                        value={form.playerNumber}
                                        onChange={handleChange}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <TextField
                                        fullWidth
                                        label="Vorname"
                                        name="firstName"
                                        value={form.firstName}
                                        onChange={handleChange}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <TextField
                                        fullWidth
                                        label="Nachname"
                                        name="lastName"
                                        value={form.lastName}
                                        onChange={handleChange}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <TextField
                                        fullWidth
                                        label="Geburtsdatum"
                                        name="birthday"
                                        type="date"
                                        InputLabelProps={{shrink: true}}
                                        value={form.birthday}
                                        onChange={handleChange}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="keeper"
                                                checked={form.keeper}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="Torwart"
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <ButtonGroup>
                                        <Button type="submit" variant="contained" color="primary">
                                            Spieler ändern
                                        </Button>
                                        <Button variant="contained" color="warning" onClick={onCancelEdit}>
                                            Abbrechen
                                        </Button>
                                    </ButtonGroup>
                                </Grid2>
                            </Grid2>
                        </form>
                        : <div key={index} style={{display: "flex", flexDirection: 'row'}}>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={existsIn(player, selectedPlayers)}
                                            onChange={() => handleSelection(player)}
                                        />
                                    }
                                    label={`${player.playerNumber} - ${player.firstName} ${player.lastName}`}
                                />
                            </div>
                            <ButtonGroup>
                                <IconButton onClick={() => handleEdit(index)}>
                                    <EditIcon/>
                                </IconButton>
                                <IconButton onClick={() => handleDelete(index)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ButtonGroup>
                        </div>
                ))}
            </Paper>


            <Paper elevation={3} style={{padding: '16px', marginBottom: '16px'}}>
                <Typography variant="h5" gutterBottom>
                    Offizielle
                </Typography>
                <form style={{display: 'flex', flexDirection: 'column', gap: "1rem"}}>
                    <div>
                        {/* Offizielle A */}
                        <Input
                            type="text"
                            placeholder="Off. A Vorname"
                            value={official.offA.firstName}
                            onChange={(e) => handleOfficialChange(e, "offA", "firstName")}
                        />
                        <Input
                            type="text"
                            placeholder="Off. A Nachname"
                            value={official.offA.lastName}
                            onChange={(e) => handleOfficialChange(e, "offA", "lastName")}
                        />
                    </div>
                    <div>
                        {/* Offizielle B */}
                        <Input
                            type="text"
                            placeholder="Off. B Vorname"
                            value={official.offB.firstName}
                            onChange={(e) => handleOfficialChange(e, "offB", "firstName")}
                        />
                        <Input
                            type="text"
                            placeholder="Off. B Nachname"
                            value={official.offB.lastName}
                            onChange={(e) => handleOfficialChange(e, "offB", "lastName")}
                        />
                    </div>
                    <div>
                        {/* Offizielle C */}
                        <Input
                            type="text"
                            placeholder="Off. C Vorname"
                            value={official.offC.firstName}
                            onChange={(e) => handleOfficialChange(e, "offC", "firstName")}
                        />
                        <Input
                            type="text"
                            placeholder="Off. C Nachname"
                            value={official.offC.lastName}
                            onChange={(e) => handleOfficialChange(e, "offC", "lastName")}
                        />
                    </div>
                    <div>
                        {/* Offizielle D */}
                        <Input
                            type="text"
                            placeholder="Off. D Vorname"
                            value={official.offD.firstName}
                            onChange={(e) => handleOfficialChange(e, "offD", "firstName")}
                        />
                        <Input
                            type="text"
                            placeholder="Off. D Nachname"
                            value={official.offD.firstName}
                            onChange={(e) => handleOfficialChange(e, "offD", "firstName")}
                        />
                    </div>
                </form>
            </Paper>
        </div>

        <PDFModal open={showPdf} onClose={() => setShowPdf(false)} sortedPlayers={sortedPlayers} teamName={teamName}
                  matchNumber={matchNumber} matchDate={matchDate} matchTime={matchTime}
                  officials={[official.offA, official.offB, official.offC, official.offD]}/>
        <PDFButton onClick={() => setShowPdf(true)}/>
        <ShareButton players={players} selectedPlayers={selectedPlayers}/>
        <DownloadButton sortedPlayers={sortedPlayers} teamName={teamName}
                        matchNumber={matchNumber} matchDate={matchDate} matchTime={matchTime}
                        officials={[official.offA, official.offB, official.offC, official.offD]}/>
        <Snackbar
            open={snackBar.show}
            autoHideDuration={6000}
            onClose={onCloseSnackbar}
        >
            <Alert variant={'filled'} severity={snackBar.type ? snackBar.type : 'warning'}>
                {snackBar.message}
            </Alert>
        </Snackbar>
    </>
}
